import React from "react";
import { Link } from "react-router-dom";

export default () => (
  <div className="row no_event">
    <div className="columns text-center">
      <h1>Live Voting Tool</h1>
      <p>No open events at the moment. Check back closer to the event starting time.</p>
    </div>
  </div>
);