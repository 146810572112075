import React, { createRef } from "react"
import { ActionCableConsumer } from "@thrash-industries/react-actioncable-provider"
import PresenceUser from "../components/presence_user"

class PresenceList extends React.Component {
  constructor(props) {
    super(props)
    this.handleReceived = this.handleReceived.bind(this)
    this.cableRef = createRef()
    setTimeout(() => this.fetchPresence(), 500)
    this.state = {
      presence: []
    }
  }

  abortController = new AbortController()
  componentWillUnmount() {
    this.abortController.abort()
  }

  fetchPresence() {
    const slug = this.props.slug
    const url = `/api/events/${slug}/presence`
    fetch(url, {signal: this.abortController.signal})
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        else if(response.status === 404) {
          throw new Error("Not Admin")
        }
        throw new Error("Network response was not ok.")
      })
      .then(response => {
        this.setState({ presence: response})
      })
      .catch((err) => {
        if(err.name === 'AbortError') return
        if(err.message === 'Not Admin') return
        alert('There was an issue connecting to the server; try again in a minute.')
      })
  }

  handleReceived(message) {
    this.setState({presence: message})
  }

  render() {
    const isAdmin = this.props.user && this.props.user.admin
    if(! isAdmin) {
      return null
    }
    const channel = {
      channel: "AdminPresenceChannel",
      event: this.props.slug
    }
    let presenceUsers
    if(this.state.presence.length === 0) {
      presenceUsers = <div className="none">None</div>
    }
    else {
      presenceUsers = this.state.presence.map((user, idx) => (
        <PresenceUser user={user} key={idx} />
      ))
    }
    return (
      <ActionCableConsumer
        ref={this.cableRef}
        channel={channel}
        onReceived={this.handleReceived}
      >
        <section className="presence_list">
          <div className="row">
          <div className="column">
            <details>
              <summary>
                <h4>Online ({this.state.presence.length})</h4>
              </summary>
              { presenceUsers }
              </details>
          </div>
          </div>
        </section>
       </ActionCableConsumer>
    )
  }
}

export default PresenceList