import React, { createRef } from "react"
import { Redirect }  from "react-router-dom"
import PropTypes from 'prop-types'
import Question from "../components/question"
import EventHeader from "../components/event_header"
import LoginView from "../components/login_view"
import { ActionCableConsumer } from "@thrash-industries/react-actioncable-provider"
import { UserContext, QuestionContext, EventContext } from '../context'

class Event extends React.Component {
  constructor(props) {
    super(props)

    this.handleReceived = this.handleReceived.bind(this)
    this.handleConnected = this.handleConnected.bind(this)
    this.handleDisconnected = this.handleDisconnected.bind(this)
    this.submitVote = this.submitVote.bind(this)
    this.closeQuestion = this.closeQuestion.bind(this)
    this.loginToggle = this.loginToggle.bind(this)

    this.cableRef = createRef()
    this.state = {
      user: null,
      fetchedUser: false,
      event: {},
      questions: [],
      showLogin: false,
      connected: false,
      disconnected: false
    }
  }

  handleReceived(message) {
    let questions = this.state.questions
    if(questions[message.id]) {
      questions[message.id] = Object.assign(questions[message.id], message)
    }
    else {
      questions[message.id] = message
    }
    this.setState({questions: questions})
  }

  handleConnected() {
    this.setState({connected: true, disconnected: false})
  }

  handleDisconnected() {
    this.setState({connected: false, disconnected: true})
  }

  submitVote(questionId, voteYesNo, voteTimestamp) {
    this.cableRef.current.perform('vote', {id: questionId, vote: voteYesNo, timestamp: voteTimestamp})
  }

  closeQuestion(questionId) {
    if(this.state.user && this.state.user.admin) {
      this.cableRef.current.perform('close', {id: questionId})
    }
  }

  loginToggle() {
    this.setState({showLogin: ! this.state.showLogin})
  }

  componentDidMount() {
    const eventSlug = this.props.slug
    this.fetchQuestions(eventSlug)
    this.checkUser(eventSlug)
  }

  abortController = new AbortController()

  componentWillUnmount() {
    this.abortController.abort()
    if(this.pingInterval) {
      clearInterval(this.pingInterval)
    }
  }

  checkUser(eventSlug) {
    const url = `/api/events/${eventSlug}/current_user`
    fetch(url, {signal: this.abortController.signal})
      .then(response => {
        if(response.status === 404) {
          throw Error()
        }
        if (response.ok) {
          return response.json()
        }
      })
      .then(json => {
        this.setState({ user: json, fetchedUser: true})
        this.setupPing()
      })
      .catch((err) => {
        if(err.name === 'AbortError') return
        this.setState({user: false, fetchedUser: true})
      })
  }
  setupPing() {
    this.pingInterval = setInterval(() => {
      this.cableRef.current.perform('ping')
    }, 30000)
  }

  fetchQuestions(eventSlug) {
    const url = `/api/events/${eventSlug}/questions`
    fetch(url, {signal: this.abortController.signal})
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw new Error("Network response was not ok.")
      })
      .then(response => {
        const event = {
          name: response.event.name,
          shortName: response.event.short_name,
          slug: response.event.slug,
          showResults: response.event.show_results,
          usesStack: response.event.status !== 'complete' && response.event.uses_stack,
          showStack: response.event.uses_stack && response.event.show_stack,
          footerHtml: response.event.footer_html,
          eventIntro: response.event.event_intro,
          liveVotes: response.event.live_votes
        }
        const questionMap = response.event.questions.reduce(function(map, obj) {
            map[obj.id] = obj;
            return map;
        }, {})
        this.setState({ questions: questionMap, event: event})
      })
      .catch((err) => {
        if(err.name === 'AbortError') return

        alert('There was an issue connecting to the server; try again in a minute, or check your URL.')
      })
  }

  render() {
    const isAdmin = this.state.user && this.state.user.admin
    const channel = {
      channel: isAdmin ? "AdminEventsChannel" : "EventsChannel",
      event: this.props.slug
    }
    const questionContext = {
      questions: this.state.questions,
      submitVote: this.submitVote,
      closeQuestion: this.closeQuestion,
      event: this.state.event
    }

    if(this.props.requireAdmin && this.state.fetchedUser && ! (this.state.user && this.state.user.admin)) {
      return <Redirect to={`/event/${this.props.slug}`} />
    }
    else {
      return (
        <ActionCableConsumer
          ref={this.cableRef}
          channel={channel}
          onReceived={this.handleReceived}
          onConnected={this.handleConnected}
          onDisconnected={this.handleDisconnected}
        >
          <UserContext.Provider value={this.state.user}>
            <QuestionContext.Provider value={questionContext}>
            <EventHeader event={this.state.event} user={this.state.user} login={this.loginToggle} connected={this.state.connected} disconnected={this.state.disconnected} />
            {! this.state.user && this.state.showLogin && <LoginView event={this.state.event} /> }
          {this.props.children}
          </QuestionContext.Provider></UserContext.Provider>
        </ActionCableConsumer>
      )
    }
  }
}

Event.propTypes = {
  requireAdmin: PropTypes.bool,
  slug: PropTypes.string
}

Event.defaultProps = {
  requireAdmin: false
}


export default Event