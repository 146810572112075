import React from "react"
import Event from "../components/event"
import {QuestionList} from "../components/question_list"
import UserStack from "../components/user_stack"
import StackList from "../components/stack_list"
import { UserContext, QuestionContext } from '../context'

class UserEvent extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const slug = this.props.match.params.eventSlug
    return (
      <Event slug={slug}>
      <div className="row event">
        <div className="columns">
          <UserContext.Consumer>
            {user => (
              <QuestionContext.Consumer>
              {({questions, submitVote, closeQuestion, event}) => (
              <>
                <div className="row main user_event">
                  <div className="column small-12 medium-7 large-8">
                    <div className="user_question_wrap">
                      <h3>Questions</h3>
                      <div className="user_questions">
                        <QuestionList questions={questions} user={user} submitVote={submitVote} closeQuestion={closeQuestion} event={event} />
                        {user && event.usesStack && <UserStack slug={slug} />}
                      </div>
                    </div>
                  </div>
                  {user && event.usesStack && event.showStack &&
                    <div className="column small-12 medium-5 large-4">
                      <StackList user={user} slug={slug} showStack={event.showStack} />
                    </div>
                  }
                </div>
                <p className="help_note text-right">
                  <span dangerouslySetInnerHTML={{__html: event.footerHtml || '' }} /> | <a href="/about" target="_blank">About the App</a>
                </p>
              </>
              )}
              </QuestionContext.Consumer>
            )}
            </UserContext.Consumer>
        </div>
      </div>
      </Event>
    )

  }
}

export default UserEvent