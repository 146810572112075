import React from "react"

export default function PresenceUser(props) {
  const user = props.user
  return (
    <div className="presence_user row">
      <div className="columns small-12 medium-4 large-3">
        <strong>{user.full_name}</strong>
      </div>
      <div className="columns">
        {user.email}
      </div>
    </div>
  )
}