import React from "react"
import { FiActivity, FiChevronDown } from 'react-icons/fi'
import { IconContext } from "react-icons"
import classnames from 'classnames'

class EventHeader extends React.Component {
  constructor(props) {
    super(props)
    this.toggleDropdown = this.toggleDropdown.bind(this)

    this.state = {
      dropdownOpen: false
    }
  }

  toggleDropdown() {
    this.setState({dropdownOpen: ! this.state.dropdownOpen})
  }

  render() {
    const event = this.props.event
    const user = this.props.user
    let userDisplay, adminLinks, connectionDisplay, disconnectHeader

    if(this.props.connected) {
      connectionDisplay = (
        <IconContext.Provider value={{  size: "1.4em", style: {display: "inline-block", marginRight: '.5rem', verticalAlign: 'middle'}}}>
        <FiActivity title="Connected & receiving data."/>
        </IconContext.Provider>
      )
    }

    if(user) {
      const dropdownClass = classnames(
        {
          dropdown: true,
          open: this.state.dropdownOpen
        }
      )

      userDisplay = (
        <div className="current_user">
          <div className={dropdownClass}>
            <strong onClick={this.toggleDropdown}>Signed in <FiChevronDown /></strong>
            <ul>
        <li><span>Signed in as {user.first_name} {user.last_name}</span></li>
              <li><span>({user.email})</span></li>
              { this.props.connected && (
                <li><span>Connected {connectionDisplay}</span></li>
              )
              }
              <li><a href={`/api/events/${event.slug}/sign-out`}>Sign out</a></li>
            </ul>
          </div>
        </div>
      )
    }
    else {
      userDisplay = <button className="button" onClick={this.props.login}>Sign in</button>
    }
    if(user && user.admin) {
      adminLinks = (<ul className="menu">
      <li className="is-active"><a className="admin" href={`/admin/${event.slug}`}>Admin</a></li>
      <li><a className="questions" href={`/admin/${event.slug}/questions`}>Questions</a></li>
      <li><a className="users" href={`/admin/${event.slug}/users`}>Users</a></li>
      </ul>
      )
    }

    if(this.props.disconnected) {
        // disconnectHeader =
        //   <div className="disconnect_header">
        //     <div className="row"><div className="columns">
        //       Your connection to the server is unstable; the app may reconnect, but please check your internet connection, and reload the page if you continue having issues. Don't miss a vote!
        //     </div></div>
        //   </div>
    }
    return (
      <>
      <header className="event_header app_section">
        <div className="row">
          <div className="columns title">
            <h2 className="show-for-medium">{event.name}</h2>
            <h2 className="show-for-small-only">{event.shortName}</h2>
            {adminLinks}
          </div>
          <div className="columns shrink text-right">
          { userDisplay }
          </div>
        </div>
      </header>
      { disconnectHeader }
      </>

    )
  }
}

export default EventHeader