import React from "react";

export const UserContext = React.createContext(null)
export const QuestionContext = React.createContext({
  questions: [],
  event: {},
  submitVote: () => {},
  closeQuestion: () => {}

})
