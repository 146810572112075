import React from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { ActionCableProvider } from "@thrash-industries/react-actioncable-provider"

import Home from "../components/home"
import UserEvent from "../components/user_event"
import AdminEvent from "../components/admin_event"


export default function App() {
  const wss = location.protocol === 'https:' ? 'wss' : 'ws'
  const eventSlug = window.location.pathname.split('/')[2]
  const port = window.location.port ? `:${window.location.port}` : ''
  const cableUrl = `${wss}://${document.domain}${port}/cable?event=${eventSlug}`

  return (
    <ActionCableProvider url={cableUrl}>
      <Router>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/event/:eventSlug" component={UserEvent} />
          <Route exact path="/admin/:eventSlug" component={AdminEvent} />
        </Switch>
      </Router>
    </ActionCableProvider>
  )
}