import React from "react";
import { Link } from "react-router-dom";
import classnames from 'classnames'
import { FiThumbsUp, FiThumbsDown } from 'react-icons/fi'
import { IconContext } from "react-icons"
import debounce from 'lodash/debounce'
import VoteYesNo from "../components/vote_yes_no"

class Question extends React.Component {
  constructor(props) {
    super(props)

    this.voteYes = this.voteYes.bind(this)
    this.voteNo = this.voteNo.bind(this)
    this.clearVote = this.clearVote.bind(this)
    this.closeQuestion = this.closeQuestion.bind(this)

    this.state = {
      voteStatus: null
    }
    if(this.props.question.status === 'open') {
      this.fetchVoteStatusNow()
    }
  }

  // If user logged-in status is not set on mount
  componentDidUpdate(prevProps) {
    if(this.props.canVote && ! prevProps.canVote) {
      this.fetchVoteStatusNow()
    }
  }

  voteYes() {
    this.voteStatus('yes')
  }

  voteNo() {
    this.voteStatus('no')
  }

  clearVote() {
    const voteTimestamp = new Date().getTime()
    this.props.submitVote(this.props.question.id, null, voteTimestamp)
    this.setState({
      voteStatus: null,
      voteTimestamp: voteTimestamp
    })
    this.fetchVoteStatus()
  }

  voteStatus(newStatus) {
    const voteTimestamp = new Date().getTime()
    this.props.submitVote(this.props.question.id, newStatus, voteTimestamp)
    this.setState({
      voteStatus: newStatus,
      voteTimestamp: voteTimestamp
    })
    this.fetchVoteStatus()
  }

  fetchVoteStatusNow() {
    if(!this.props.canVote) {
      return null
    }
    const url = `/api/events/${this.props.slug}/questions/${this.props.question.id}/votes`
    fetch(url)
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw new Error("Network response was not ok.")
      })
      .then(response => {
        this.setState({ voteStatus: response.kind})
      })
      .catch((err) => {
        Rollbar.error("Error fetching vote status", err)
        alert('There was an issue connecting to the server; try again in a minute.')
      })

  }
  fetchVoteStatus = debounce( () => {
    this.fetchVoteStatusNow()
    }, 1000)

  closeQuestion() {
    var closeFunction = this.props.closeQuestion
    const id = this.props.question.id
    if(window.confirm("Do you really want to close this question?")) {
      closeFunction(id)
    }
  }

  get userCanVote() {
    const question = this.props.question
    if(question.status === 'open' && this.props.canVote) {
      return ! question.requires_special_voter || this.props.special
    }
    else {
      return false
    }
  }

  render() {
    const question = this.props.question
    const signedIn = this.props.signedIn
    const canVote = this.props.canVote
    const showLive = this.props.showLive
    const statusOpen = question.status === 'open' && this.userCanVote
    const statusClosed = question.status === 'closed'

    const showCounts =  this.props.admin || (!question.hide_results && (statusClosed || showLive))
    const classNames = classnames(
      {
        question: true,
        status_open: statusOpen,
        status_closed: question.status === 'closed',
        signed_in: signedIn,
        show_results: this.props.showResults
      }
    )
    var voteButtons, statusLabel, closeButton, specialIndicator, hideResultsIndicator, supermajorityIndicator
    if(this.userCanVote) {
      const voteClass = classnames({
        vote_buttons: true,
        status_yes: this.state.voteStatus === 'yes',
        status_no: this.state.voteStatus === 'no'
      })
      if(this.state.voteStatus === null) {
        voteButtons = <VoteYesNo voteYes={this.voteYes} voteNo={this.voteNo} question={question} />
      }
      else {
        let voteDisplay
        if(this.props.question.kind === 'select_one') {
          const candidateIndex = this.state.voteStatus === 'yes' ? 0 : 1
          voteDisplay = <p>You voted for <br/><strong>{this.props.question.candidates[candidateIndex]}</strong>.</p>
        }
        else {
          voteDisplay = <p>You voted <strong>{this.state.voteStatus}</strong>.</p>
        }
        voteButtons = (
          <div className="vote_buttons status">
            {voteDisplay}
            <p><button className="button secondary hollow clear_vote" onClick={this.clearVote}>Clear my vote</button></p>
          </div>
        )
      }
      statusLabel = <div className="status_label">Voting Now</div>
      if(this.props.admin) {
        closeButton = (<div className="close_button">
        <button className="button small alert " onClick={this.closeQuestion}>Close</button>
        </div>)
      }
    }
    if(this.props.admin && question.requires_special_voter) {
      specialIndicator = <span className="special" title="Requires special voter">&#9733;&nbsp;</span>
    }
    if(this.props.admin && question.super_majority) {
      supermajorityIndicator = <span className="supermajority" title="Requires 2/3 Vote">&nbsp;&#8532;</span>
    }
    if(this.props.admin && question.hide_results) {
      hideResultsIndicator = <div className="hidden_results">(Results hidden)</div>
    }
    var voteResult
    if(showCounts) {
      let voteIcon
      if(question.kind === "yes_no") {
        if(question.result === 'passed') {
          voteIcon = <IconContext.Provider value={{ color: "#00A115", size: "1.2em", style: {display: "inline-block", verticalAlign: '-3px'}}}><FiThumbsUp /></IconContext.Provider>
        }
        else if(question.result === 'failed') {
          voteIcon = <IconContext.Provider value={{ color: "#555", size: "1.2em", style: {display: "inline-block", verticalAlign: '-5px'} }}><FiThumbsDown /></IconContext.Provider>
        }
      }
      // else if(question.result !== 'pending') {
      //   if(question.votes_yes == question.votes_no) {
      //     voteIcon = <strong className="initials">—</strong>
      //   }
      //   else {
      //     const candidate = question.result === 'passed' ? question.candidates[0] : question.candidates[1]
      //     const initials = candidate.match(/\b(\w)/g).join('')
      //     voteIcon = <strong className="initials">{initials}</strong>
      //   }
      // }
      let voteCount = `${question.votes_yes} / ${question.votes_no}`
      voteResult = (
        <div className="results">
          { this.props.showResults && voteIcon }
          { (showLive || statusClosed) && voteCount }
        {hideResultsIndicator}</div>
      )
    }
    return (
      <div className={classNames}>
        <div className="wrap">
          <div className="votes">
            {voteResult}
            {signedIn && voteButtons}
            {! signedIn && statusOpen && (
              <p className="signed_out"><em>Sign in to vote at this event.</em></p>
            )}
          </div>
          <div className="details">
            {statusLabel}
            <h4>{specialIndicator}{question.title}{supermajorityIndicator}</h4>
          </div>
            { closeButton }
        </div>
      </div>
    )
  }

}

export default Question