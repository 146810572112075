import React, { createRef } from "react"
import { ActionCableConsumer } from "@thrash-industries/react-actioncable-provider"
import StackUser from "../components/stack_user_listing"
import { stackTypes } from "../stack_types"

function StackTypeList(props) {
  const typeName = props.typeName
  const users = props.users

  if(users.length === 0) {
    return null
  }
  const handleDelete = props.handleDelete
  const stackUsers = props.users.map((user, idx) => (
    <StackUser user={user} handleDelete={handleDelete} key={idx} isAdmin={props.isAdmin}/>
  ))
  return (
    <div className="stack_type row">
      <div className="columns">
        <h4>{typeName}</h4>
        {stackUsers}
      </div>
    </div>
  )
}

class StackList extends React.Component {
  constructor(props) {
    super(props)
    this.handleReceived = this.handleReceived.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.cableRef = createRef()
    this.fetchStack()
    this.state = {
      stack: []
    }
  }

  abortController = new AbortController()
  componentWillUnmount() {
    this.abortController.abort()
  }

  fetchStack() {
    const slug = this.props.slug
    const url = `/api/events/${slug}/stack.json`
    fetch(url, {signal: this.abortController.signal})
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        if(response.status === 404) {
          throw new Error("Not Admin")
        }
        throw new Error("Network response was not ok.")
      })
      .then(response => {
        this.setState({ stack: response})
      })
      .catch((err) => {
        if(err.name === 'AbortError') return
        if(err.message === 'Not Admin') return
        alert('There was an issue connecting to the server; try again in a minute.')
      })
  }

  handleReceived(message) {
    this.setState({stack: message})
  }

  handleDelete(user_id) {
    this.cableRef.current.perform('remove', {user_id: user_id})
  }

  render() {
    const isAdmin = this.props.user && this.props.user.admin
    if(! isAdmin && ! this.props.showStack) {
      return null
    }
    const channel = {
      channel: "AdminStackChannel",
      event: this.props.slug
    }
    let stackList

    if(this.state.stack.length === 0) {
      stackList = <div className="none">None</div>
    }
    else {
      stackList = Object.keys(stackTypes).map((key, idx) => {
      let users = this.state.stack.filter(user => user.kind === key)
      return <StackTypeList typeName={stackTypes[key]} users={users} handleDelete={this.handleDelete} key={idx} isAdmin={isAdmin} />
      })
    }
    return (
      <ActionCableConsumer
        ref={this.cableRef}
        channel={channel}
        onReceived={this.handleReceived}
      >
        <section className="stack_list">
          <h3>Stack</h3>
          { stackList }
        </section>
       </ActionCableConsumer>
    )
  }
}

export default StackList