import React, { createRef } from "react"
import { ActionCableConsumer } from "@thrash-industries/react-actioncable-provider"
import { stackTypes } from "../stack_types"
import classnames from 'classnames'

class UserStack extends React.Component {
  constructor(props) {
    super(props)
    this.handleToggle = this.handleToggle.bind(this)
    this.handleReceived = this.handleReceived.bind(this)
    this.handleKindChange = this.handleKindChange.bind(this)
    this.cableRef = createRef()
    this.fetchStack()
    this.state = {
      stack: {kind: null},
      selectedKind: ''
    }
  }

  fetchStack() {
    const slug = this.props.slug
    const url = `/api/events/${slug}/my_stack`
    fetch(url)
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw new Error("Network response was not ok.")
      })
      .then(response => {
        this.setState({ stack: response, selectedKind: response.kind || ''})
      })
      // .catch(() => alert('There was an issue connecting to the server; try again in a minute.'))
  }

  handleReceived(message) {
    this.setState({stack: message})
  }

  handleToggle() {
    if(this.state.stack.kind) {
      this.cableRef.current.perform('remove_me')
      this.setState({selectedKind: ''})
    }
    else {
      this.cableRef.current.perform('add_me', {kind: this.state.selectedKind})
    }
  }

  handleKindChange(event) {
    this.setState({selectedKind: event.target.value});
  }

  render() {
    let stackStatus, stackButton
    const channel = {
      channel: "StackChannel",
      event: this.props.slug
    }
    const isOnStack = !! this.state.stack.kind
    if(isOnStack) {
      const stackKind = stackTypes[this.state.stack.kind]
      stackStatus = (<div className="on_stack"><strong>On Stack: {stackKind}</strong></div>)
      stackButton = 'Leave Stack'
    }
    else {
      stackButton = 'Add Me to Stack'
    }
    const selectOptions = Object.entries(stackTypes).map((type, idx) => {
      return <option value={type[0]} key={idx}>{type[1]}</option>
    })

    const stackClass = classnames(
      {
        user_stack: true,
        on_stack: isOnStack
      }
    )
    return (
    <ActionCableConsumer
      ref={this.cableRef}
      channel={channel}
      onReceived={this.handleReceived}
    >
        <div className={stackClass}>
          <div className="row">
              <div className="columns type_select">
              {! isOnStack &&
                <form><select value={this.state.selectedKind} onChange={this.handleKindChange}>
                  <option value=''>Select stack type</option>
                  {selectOptions}
                </select>
                </form>
              }
              { isOnStack && stackStatus }
            </div>
            <div className="columns toggle_stack"><button className="button" disabled={!this.state.selectedKind} onClick={this.handleToggle}>{stackButton}</button></div>
          </div>
        </div>
      </ActionCableConsumer>
    )
  }

}

export default UserStack