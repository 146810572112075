import React from "react"
import Question from "../components/question"
import sortBy from 'lodash-es/sortBy'

export function QuestionList(props) {
  const signedIn = !! props.user
  const admin = props.user && props.user.admin
  const canVote = props.user && props.user.can_vote
  const showLive = (props.event && props.event.liveVotes) || (props.user && props.user.admin)

  if(Object.keys(props.questions).length) {
    const sortedQuestions = sortBy(Object.values(props.questions), q => q.closed_at ).reverse().filter(question => (question.status !== 'pending' && question.status !== 'deleted'))
    const allQuestions = sortedQuestions.map((question, index) => (
      <Question question={question} slug={props.event.slug} showLive={showLive} signedIn={signedIn} admin={admin} special={props.user && props.user.special} canVote={canVote} submitVote={props.submitVote} closeQuestion={props.closeQuestion} key={question.id} showResults={props.event.showResults} />
    ))
    return allQuestions
  }
  else {
    return <div className="row">
    <div className="columns small-12 medium-10 large-8">
    <h4>Hey there!</h4>
    { (! signedIn || ! admin) && <div dangerouslySetInnerHTML={{__html: props.event.eventIntro || '' }} />}
    {! signedIn &&
      (
        <p>To vote at this event, hit the Sign In button.</p>
      )
    }
    { signedIn &&
      (
        <p>Thanks for signing in. There haven’t been any votes yet.</p>
      )
    }
    </div>
    </div>
  }
}