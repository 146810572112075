import React from "react"
import TimeAgo from 'timeago-react'

export default function StackUser(props) {
  const user = props.user
  const handleDelete = props.handleDelete
  return (
    <div className="stack_user row">
      <div className="columns">
        <strong>{user.name}</strong>
        <em><TimeAgo
          datetime={user.created_at}
          locale='en_US'
          opts={{minInterval: 10}}
          />
        </em>
      </div>
      { props.isAdmin &&
      <div className="columns shrink">
        <button className="button small hollow remove" onClick={() => handleDelete(user.user_id)}>Remove</button>
        </div>
      }
    </div>
  )
}