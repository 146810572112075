import React from "react"

export default function VoteYesNo(props) {
  let buttons
  if(props.question.kind === 'select_one') {
    const candidates = props.question.candidates
    buttons = <div className="select_one">
      <button className="button choice" onClick={props.voteYes}>{candidates[0]}</button>
      <button className="button choice" onClick={props.voteNo}>{candidates[1]}</button>
    </div>
  }
  else {
    buttons = <>
      <button className="button vote_yes" onClick={props.voteYes}>Yes</button>
      <button className="button vote_no" onClick={props.voteNo}>No</button>
    </>
  }
  return (
    <div className="vote_buttons">
      <p>Your vote:</p>
      {buttons}
    </div>

  )
}