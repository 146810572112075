import React from "react";
import forOwn from 'lodash-es/forOwn'
import { FiThumbsUp, FiThumbsDown } from 'react-icons/fi'
import { IconContext } from "react-icons"

class LoginView extends React.Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)

    this.state = {
      email: '',
      verifyStatus: null,
      checkingEmail: false
    }
  }
  handleSubmit() {
    if(this.state.email === '') {
      return false
    }
    this.verifyStatus = null
    this.checkingEmail = true
    event.preventDefault()
    const formData = this.buildFormData({
      'email': this.state.email
    })
    fetch(`/api/events/${this.props.event.slug}/send_link`, {
        method: 'PUT',
        body: formData
      })
      .then(response => {
        this.checkingEmail = false
        if (response.ok) {
          this.setState({verifyStatus: true, email: ''})
        }
        else {
          this.setState({verifyStatus: false})
        }
      })
    .catch((err) => {
      Rollbar.error("Error signing in", err)
      alert("There was an error trying to sign in.")
    })
  }

  buildFormData(data) {
    var formData = new FormData()
    forOwn(data, (value, key) => {
      formData.append(key, value)
    })
    return formData
  }

  handleChange(event) {
    this.setState({email: event.target.value, verifyStatus: null})
  }
  render() {
    return (
      <div className="login">
        <div className="row">
          <div className="columns">
            { this.state.verifyStatus === false && (
              <div className="alert">
                <IconContext.Provider value={{ color: "#EB1D27", size: "2em", style: {display: "inline-block", verticalAlign: '-5px'} }}><FiThumbsDown /></IconContext.Provider>
                That email was not found! Try again, and make sure you’re using the correct email for this event.
              </div>
            )}
            { this.state.verifyStatus === true && (
              <div className="success">
              <IconContext.Provider value={{ color: "#00A115", size: "2em", style: {display: "inline-block", verticalAlign: '-3px'}}}><FiThumbsUp /></IconContext.Provider>
              Ok! You should receive an email with a magic link; just click the link to sign in. Please close this tab.
              </div>
            )}
            { this.state.verifyStatus !== true && (
              <form onSubmit={this.handleSubmit}>

            <p>To sign in, enter your email and we’ll send you a link. Use the correct email address for this event; ask the event organizers if you have doubts.</p>
            <div className="input email">
              <label>Your email</label>
              <input type="email" autoFocus value={this.state.email} onChange={this.handleChange} />
            </div>
            <input type="submit" className="button submit" value="Get sign-in link" disabled={! this.state.email || this.state.checkingEmail} />
            </form>
            )}
          </div>
        </div>
      </div>
    )
  }

}

export default LoginView