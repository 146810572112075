import React from "react"
import Event from "../components/event"
import {QuestionList} from "../components/question_list"
import StackList from "../components/stack_list"
import UserStack from "../components/user_stack"
import PresenceList from "../components/presence_list"
import { UserContext, QuestionContext } from '../context'

class AdminEvent extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const slug = this.props.match.params.eventSlug

    return (
      <Event slug={slug} requireAdmin={true}>
        <UserContext.Consumer>
          {user => (
            <QuestionContext.Consumer>
            {({questions, submitVote, closeQuestion, event}) => (
              <>
                <div className="row main admin_event">
                  <div className="columns">
                    <QuestionList questions={questions} user={user} submitVote={submitVote} closeQuestion={closeQuestion} event={event}/>
                  </div>
                  {event && event.usesStack &&
                    <div className="columns">
                      <StackList user={user} slug={slug} />
                      {user && <UserStack slug={slug} />}
                    </div>
                  }
                </div>
                <PresenceList user={user} slug={slug} />
              </>
            )}
            </QuestionContext.Consumer>
          )}
          </UserContext.Consumer>
      </Event>
    )

  }
}

export default AdminEvent